<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改优惠券' : '添加优惠券'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="优惠券名称：" prop="couponName">
        <el-input
          v-model="modalData.couponName"
          placeholder="请输入优惠券名称"
        ></el-input>
      </el-form-item>

      <el-form-item label="优惠券编号：" prop="couponCode">
        <el-input
          v-model="modalData.couponCode"
          placeholder="请输入优惠券编号"
          :maxLength="50"
          double
        ></el-input>
      </el-form-item>

      <el-form-item label="发放数量：" prop="couponNums">
        <eb-input-number
          v-model="modalData.couponNums"
          type="text"
          placeholder="请输入发放数量"
        >
        </eb-input-number>
      </el-form-item>

      <el-form-item label="优惠券面额：" prop="couponPrice">
        <eb-input-number
          type="text"
          v-model="modalData.couponPrice"
          placeholder="请输入面额"
          double
        >
          <template #suffix>元</template>
        </eb-input-number>
      </el-form-item>

      <el-form-item label="使用限额：" prop="couponRule">
        <eb-input-number
          type="text"
          v-model="modalData.couponRule"
          placeholder="请输入使用最低限额"
          double
        >
          <template #suffix>元</template>
        </eb-input-number>
      </el-form-item>

      <el-form-item label="领取次数：" prop="canGetNums">
        <eb-input-number
          v-model="modalData.canGetNums"
          placeholder="请输入可领取次数"
        ></eb-input-number>
      </el-form-item>

      <el-form-item label="有效期类型：" prop="type">
        <el-radio-group v-model="modalData.type">
          <el-radio :label="this.const.COUPON_EXPIRE_CODE.DAY">按天数</el-radio>
          <el-radio :label="this.const.COUPON_EXPIRE_CODE.DATE"
            >按时间段</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="有效期天数："
        prop="days"
        v-if="modalData.type === this.const.COUPON_EXPIRE_CODE.DAY"
      >
        <eb-input-number placeholder="有效天数" v-model="modalData.days">
          <template #suffix>天</template>
        </eb-input-number>
      </el-form-item>

      <el-form-item
        label="有效期："
        prop="date"
        v-if="modalData.type === this.const.COUPON_EXPIRE_CODE.DATE"
      >
        <el-date-picker
          v-model="modalData.date"
          type="daterange"
          unlink-panels
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :shortcuts="shortcuts"
          :defaultTime="defaultTime"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>
      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
import moment from "moment";

export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType"],
  data() {
    return {
      isShowNum: false,
      
      formName: "form",
      shortcuts: [
        {
          text: "7天",
          value: () => {
            return this.getTimeArea(7);
          },
        },
        {
          text: "30天",
          value: () => {
            return this.getTimeArea(30);
          },
        },
        {
          text: "90天",
          value: () => {
            return this.getTimeArea(90);
          },
        },
      ],
      defaultTime: [
        new Date(2000, 1, 1, 0, 0, 0),
        new Date(2000, 2, 1, 23, 59, 59),
      ],
      ruleValidate: {
        type: [
          {
            required: true,
            message: "请选择过期方式",
          },
        ],
        couponCode: [
          {
            required: true,
            message: "请输入优惠券编码",
          },
        ],
        canGetNums: [
          {
            required: true,
            message: "请输入可领取次数",
          },
        ],
        couponName: [
          {
            required: true,
            message: "请输入优惠券名称",
          },
        ],
        couponNums: [
          {
            required: true,
            message: "请输入优惠券数量",
          },
        ],
        couponPrice: [
          {
            required: true,
            message: "请输入优惠券面额",
          },
          {
            validator: (rule, val, callback) => {
                console.log(`满${this.modalData.couponRule}减${this.modalData.couponPrice}`);
              if(Number(this.modalData.couponRule) < Number(val)){
                callback('优惠券使用限额不能小于面额');
              }
                callback();
            },
          },
        ],
        couponRule: [
          {
            required: true,
            message: "请输入优惠券使用限额",
          },{
            validator: (rule, val, callback) => {
              console.log(`满${this.modalData.couponRule}减${this.modalData.couponPrice}`);
              if(Number(val) < Number(this.modalData.couponPrice)){
                callback('优惠券使用限额不能小于面额');
              }
                callback();
            },
          },
        ],
        days: [
          {
            required: true,
            message: "请输入优惠券有效期",
          },
        ],
        date: [
          {
            required: true,
            message: "请输入优惠券有效期",
          },
        ],
      },
    };
  },
  methods: {
    changeTime(e) {
      this.modalData.playTime = this.$filter.dateFormat(e);
    },

    getTimeArea(day) {
      let start = moment(new Date());
      let end = moment(new Date());
      start.startOf("day");
      end.add(day, "days");
      end.endOf("day");
      return [start, end];
    },

    submit() {
      this.validateForm().then((res) => {
        let obj = this.deepClone(this.modalData);
        obj.couponPrice = obj.couponPrice * 100;
        obj.couponRule = obj.couponRule * 100;

        if (!this.validatenull(obj.date)) {
          obj.startTime = this.$filter.dateFormat(obj.date[0]);
          obj.endTime = this.$filter.dateFormat(obj.date[1]);
        }

        delete obj.updateAt;
        delete obj.createAt;

        delete obj.date;
        this.$emit("submit", obj);
      });
    },
  },
};
</script>
