<template>
  <div>
    <el-form>
      <el-row :gutter="20" class="search-box">
        <el-col :span="4">
          <el-select placeholder="优惠券状态" v-model="query.status" clearable>
          
            <el-option
              v-for="(item, index) in this.const.COUPON_STATUS"
              :label="item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-select placeholder="优惠券有效期" clearable v-model="query.isInTime">
       
            <el-option label="有效内" :value="1"></el-option>
          </el-select>
        </el-col>

        <el-col :span="4">
          <el-button type="primary" @click="getList(1)">查询</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div>
      <el-button class="but" type="primary" @click="showAddCoupon"
       v-auth="this.per.COUPON_LIST_ADD"
        >添加优惠券</el-button
      >
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="名称" prop="couponName"></el-table-column>
      <el-table-column label="数量" prop="couponNums"></el-table-column>
      <el-table-column label="面额（元）" prop="couponPrice">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.couponPrice / 100) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="最低使用金额（元）" prop="couponRule">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.couponRule / 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="有效期" prop="type">
        <template #default="scope">
          <span>{{
            this.const.COUPON_EXPIRE_CODE.DAY === scope.row.type
              ? `${scope.row.days}天`
              : `${scope.row.startTime} - ${scope.row.endTime}`
          }}</span>
        </template>
      </el-table-column>

      <el-table-column label="可领取次数" prop="canGetNums"></el-table-column>
      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.COUPON_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showEditCoupon(scope.row)"
              v-auth="this.per.COUPONT_UPDATE"
          >
            修改
          </span>

          <span
            v-auth="this.per.COUPONT_PUTWAY"
            v-if="
              this.const.COUPON_STATUS_CODE.SOLD_OUT === scope.row.status ||
              scope.row.status === 0
            "
            class="option option-primary"
            @click="
              updateStatus(scope.row, this.const.COUPON_STATUS_CODE.PUTWAY)
            "
          >
            上架
          </span>

          <span
            v-auth="this.per.COUPONT_PUTWAY"
            v-if="this.const.COUPON_STATUS_CODE.PUTWAY === scope.row.status"
            class="option option-primary"
            @click="
              updateStatus(scope.row, this.const.COUPON_STATUS_CODE.SOLD_OUT)
            "
          >
            下架
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="doSumbit"
    ></EditModal>
  </div>
</template>

<script>
import EditModal from "./component/EditModal";
import {
  fetchCouponList,
  addCoupon,
  updateCoupon,
  couponPutaway,
} from "@/api/coupon";
export default {
  components: { EditModal },
  data() {
    return {
      dataSource: [],
      query: {
       
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    updateStatus(obj, type) {
      couponPutaway({ id: obj.id, status: type }).then((res) => {
        this.$message.success("操作成功");
        this.getList();
      });
    },
    showAddCoupon() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },
    showEditCoupon(data) {
      this.modalData = this.deepClone(data);
      this.modalData.couponPrice = this.util.formatNum(
        this.modalData.couponPrice / 100
      );
      this.modalData.couponRule = this.util.formatNum(
        this.modalData.couponRule / 100
      );
      if (this.modalData.startTime) {
        this.modalData.date = [
          new Date(this.modalData.startTime),
          new Date(this.modalData.endTime),
        ];
      }
      console.log(this.modalData.date);

      this.modalType = "edit";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    doSumbit(obj) {
      let result = updateCoupon;
      let msg = "修改成功！";
      if (this.validatenull(obj.id)) {
        result = addCoupon;
        msg = "添加成功！";
      }

      result(obj).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchCouponList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
